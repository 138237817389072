/*
 *
 *   SARA INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/

// Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Variables, Mixins
@import "inspinia/variables";
@import "inspinia/mixins";

// INSPINIA Theme Elements
@import "inspinia/typography";
@import "inspinia/navigation";
@import "inspinia/top_navigation";
@import "inspinia/buttons";
@import "inspinia/badges_labels";
@import "inspinia/elements";
@import "inspinia/sidebar";
@import "inspinia/base";
@import "inspinia/pages";
@import "inspinia/chat";
@import "inspinia/metismenu";
@import "inspinia/spinners";

// Landing page styles
@import "inspinia/landing";

// RTL Support
@import "inspinia/rtl";

// For demo only - config box style
@import "inspinia/theme-config";

// INSPINIA Skins
@import "inspinia/skins";
@import "inspinia/md-skin";

// Media query style
@import "inspinia/media";

// Custom style
// Your custom style to override base style
@import "inspinia/custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}

// Used for Dropzone media boxes
.box {
  background: #fff;
  transition: all 0.2s ease;
  border: 1px dashed #dadada;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-clip: padding-box;
  padding: 0 0 10px 0;
  min-height: 340px;
}

.box:hover {
  border: 1px solid #525C7A;
}

.box span.box-title {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

.box .box-content {
  padding: 10px 10px 16px 10px;
  border-radius: 0 0 2px 2px;
  background-clip: padding-box;
  box-sizing: border-box;
}

.box .box-content p {
  color: #515c66;
  text-transform: none;
}

.hidden-for-fadein{
  display: none;
}
// Dropzone media boxes end

// Bootstrap modal
@media (min-width: 992px) {
  .modal-lg
  {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  .modal-lg
  {
    width: 1100px;
  }
}

.corner-green {
  border-right: 0.6em solid $success;
  border-bottom: 0.6em solid $success;
}

.corner-red {
  border-right: 0.6em solid $red;
  border-bottom: 0.6em solid $red;
}

.ibox-content dl dd {
  white-space: pre-line; 
}
