/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}


/* _float */
.float-right {
  float: right!important;
}

/* footable active*/
.pagination .footable-page.active a {
  background-color: #1ab394;
  border-color: #1ab394;
  color: white;
}

/* borderless: table without borders */
table.borderless td,table.borderless th {
  border: none !important;
}

/* list-group-flush: flush / remove Borders */
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

/* gallery title background */
.blueimp-gallery>.close, .blueimp-gallery>.title {
  background: rgba(0,0,0,.8);
  padding: 20px 40px;
}